import { Inject, Injectable, Optional } from '@angular/core';
import { DocumentRefService, WindowRefService } from '@callrail/looky/util';
import { from, tap, Observable, ReplaySubject } from 'rxjs';

import { ErrorReporterService } from '../error-reporter/error-reporter.service';

declare const SM: any; // semrush js sdk

type ClientMethod = 'getAccessToken';

interface SemrushSdk {
  client: (methodName: ClientMethod, ...params) => Promise<string>;
  init: () => Promise<unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class SemrushIframeService {
  private semrushToken = new ReplaySubject<string>(1);
  public readonly semrushToken$ = this.semrushToken.asObservable();

  constructor(
    private documentRefService: DocumentRefService,
    private windowRefService: WindowRefService,
    private errorReporterService: ErrorReporterService,
    @Optional() @Inject('sm') private sm?: SemrushSdk
  ) {
    if (this.windowRefService.inSemrushIframe()) {
      this.appendScriptToBody();
    }
  }

  private get sdk(): SemrushSdk {
    return this.sm || SM;
  }

  public refreshToken(): Observable<string> {
    return from(this.sdk.client('getAccessToken')).pipe(
      tap((accessToken) => {
        this.semrushToken.next(accessToken);
      })
    );
  }

  private appendScriptToBody(): void {
    this.windowRefService.nativeWindow().onSmSdkLoad = () => {
      this.sdk
        .init()
        .then(() => this.sdk.client('getAccessToken'))
        .then((accessToken) => {
          this.semrushToken.next(accessToken);
        })
        .catch((error) => {
          this.handleSdkError(error);
          this.semrushToken.next(null);
        });
    };
    const script = this.documentRefService
      .nativeDocument()
      .createElement('script');
    // this file was downloaded directly from https://static.semrush.com/app-center/sdk.js
    // and then minified with an online minifier https://www.toptal.com/developers/javascript-minifier/
    // and then uploaded to our cdn following these instructions https://callrail.atlassian.net/l/c/SvRVYswq
    script.src =
      'https://static.semrush.com/developer-app-center/static/sdk_0.0.7.js';
    script.type = 'text/javascript';
    this.documentRefService.nativeDocument().body.appendChild(script);
  }

  private handleSdkError(error): Error {
    const initialCode = error.status;
    error.status = 500; // ensure we _do_ actually badger this no matter what code it had initially
    const metadata = { uncaught: true, code: initialCode };
    this.errorReporterService.report(this.errorWithName(error), metadata);
    return error;
  }

  private errorWithName(error: Error): Error {
    // for easier scanning in Honeybadger UI and team/individual assignment
    error.name = `SemrushIframeService::${error.name}`;
    return error;
  }
}
