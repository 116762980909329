import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrentContextService } from '../../services/current-context/current-context.service';
import { CompaniesDropdownComponent } from '../companies-dropdown/companies-dropdown.component';

@Component({
  selector: 'core-select-company-prompt',
  templateUrl: './select-company-prompt.component.html',
  styleUrls: ['./select-company-prompt.scss'],
})
export class SelectCompanyPromptComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  public companyId: string;
  public selectCompanyImagePath: string;
  public message: string;

  @ViewChild('dropdown', { static: true })
  public dropdown: CompaniesDropdownComponent;

  constructor(
    public context: CurrentContextService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  public ngOnInit() {
    this.context.clearCompany();
    this.onQueryChanges();
  }

  public ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public selectCompany(companyId: number): void {
    this.navigateToRedirect(companyId);
  }

  private onQueryChanges() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy))
      .subscribe((queryParams) => {
        this.message = this.selectMessage();
        if (this.dropdown) {
          this.dropdown.openMenu();
        }
      });
  }

  private destinationUrl() {
    return this.route.snapshot.queryParamMap.get('ref_url');
  }

  private navigateToRedirect(companyId: number): void {
    // the RefUrlGuard ensures that there is always a ref_url in the query string
    this.router.navigate([this.destinationUrl()], {
      queryParams: { company_id: companyId },
      replaceUrl: true,
    });
  }

  // This is slightly hacky. Consider refactoring if you need to add to this method.
  private selectMessage(): string {
    const destination = this.destinationUrl()?.split('/') || [];
    if (
      isEqual(destination.slice(1, 3), ['settings', 'integrations']) &&
      destination[3]
    ) {
      return 'You can activate this integration for one company at a time.';
    }
  }
}
